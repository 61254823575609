import React, { useState } from "react";
import {
    Accordion,
    IconButton,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import colors from "../styles/colors";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function QuestionAccordion({ data }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <Accordion
                sx={{
                    border: `1px solid ${colors.gray}`,
                    borderRadius: 2,
                    background: colors.white,
                    "@media (max-width: 910px)": {
                        fontSize: "0.9em",
                    },
                }}
                expanded={!!expanded}
                square
                onChange={() => setExpanded(!expanded)}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ fontWeight: 600, color: colors.mainHeaderGray }}
                >
                    <StyledFlex>
                        <StyledQ>{data.question}</StyledQ>

                        <div>
                            <IconButton
                                color="primary"
                                component="span"
                                size="large"
                                aria-label={`expand: ${data.question}`} 
                            >
                                {expanded ? (
                                    <RemoveCircleOutlineIcon
                                        fontSize={"small"}
                                        style={{ fill: colors.brandGreen }}
                                    />
                                ) : (
                                    <AddCircleOutlineIcon
                                        fontSize={"small"}
                                        style={{ fill: colors.brandGreen }}
                                    />
                                )}
                            </IconButton>
                        </div>
                    </StyledFlex>
                </AccordionSummary>
                <AccordionDetails
                    sx={{ textAlign: "left", color: colors.mainHeaderGray }}
                >
                    {data.answer}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
const StyledFlex = styled('div')`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    > div span {
        padding: 0;
    }
`;
const StyledQ = styled('p')`
    margin: 0;
    text-align: left;
    font-weight: 500;
`;
