import React, { memo, useMemo } from "react";
import { styled } from "@mui/material/styles";
import colors from "../styles/colors";
import { Grid } from "@mui/material";
import FooterModals from "./FooterModals";
import Divider from "@mui/material/Divider";
import logo from "../images/logo_white_O_red_2x.png";

const Yritys = [
    { id: 1, label: "Yritys", type: "header", href: "" },
    { id: 2, label: "Etusivulle", type: "item", href: "#" },
    { id: 3, label: "Pienkeräykset", type: "item", href: "/pienkeraykset" },
    {
        id: 4,
        label: "Joukkorahoitukset",
        type: "item",
        href: "/joukkorahoitukset",
    },
    { id: 5, label: "Meistä", type: "item", href: "#about" },
    { id: 6, label: "Usein kysyttyä", type: "item", href: "#questions" },
    {
        id: 7,
        label: "Blogi",
        type: "item",
        href: "/blog",
    },
];

const Palvelu = [
    { id: 1, label: "Palvelu", type: "header" },
    { id: 2, label: "Aloita kampanja", type: "item", href: "/luo-kampanja" },
    { id: 2, label: "Aloita keräys", type: "item", href: "/luo-kampanja" },
    // { id: 3, label: "Järjestöt", type: "item" },
    // { id: 4, label: "Yhteisörahoitus", type: "item" },
    // { id: 5, label: "Vapaaehtoistyö", type: "item" },
];

const yhteys = [
    { id: 1, label: "Yhteystiedot", type: "header" },
    {
        id: 5,
        label: "info@parempiatekoja.fi",
        href: "mailto:info@parempiatekoja.fi",
        type: "email",
    },
]

export const Footer = memo(() => {

    const company = useMemo(() => {
        return Yritys.map((item) => {
            return item.type === "header" ? (
                <StyledHeader key={item.label}>{item.label}</StyledHeader>
            ) : (
                <StyledLink key={item.label} href={item.href}>
                    {item.label}
                </StyledLink>
            );
        });
    }, []);

    const palvelu = useMemo(() => {
        return Palvelu.map((item) => {
            return item.type === "header" ? (
                <StyledHeader key={item.label}>{item.label}</StyledHeader>
            ) : (
                <StyledLink key={item.label} href={item.href}>
                    {item.label}
                </StyledLink>
            );
        });
    }, []);

    const connection = useMemo(() => {
        return yhteys.map((item) => {
            return item.type === "header" ? (
                <StyledHeader key={item.label}>{item.label}</StyledHeader>
            ) : (
                <StyledLink key={item.label} href={item.href}>
                    {item.label}
                </StyledLink>
            );
        });
    }, []);

    return (
        <StyledWrapper id="contact">
            <StyledInnerWrapper>
                <Grid container spacing={2}>
                    <Grid item md={5} sm={12} xs={12}>
                        <StyledLogoAndSlogan>
                            <StyledLogo src={logo} alt="Parempia Tekoja logo" />
                            <StyledSlogan>Löydä oma tapasi auttaa</StyledSlogan>
                        </StyledLogoAndSlogan>
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <StyledInnerGrid>{company}</StyledInnerGrid>
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <StyledInnerGrid>
                            {palvelu}
                        </StyledInnerGrid>
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <StyledInnerGrid>{connection}</StyledInnerGrid>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "20px",
                        marginTop: "20px",
                        backgroundColor: colors.brandGreen,
                    }}
                />
                <span>
                    {" "}
                    <FooterModals />
                </span>
            </StyledInnerWrapper>
        </StyledWrapper>
    );
});


const StyledHeader = styled('p')`
    font-weight: bold;
    color: ${colors.white};
    margin-top: 15px;
    font-size: 1rem;
    @media (max-width: 550px) {
        font-size: 1.3rem;
    }
`;

const StyledLink = styled('a')`
    display: block;
    color: ${colors.white};
    text-decoration: none;
    font-size: 0.8rem;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: 550px) {
        height: 48px;
        font-size: 1rem;
    }
`;

const StyledWrapper = styled('div')`
    width: 100%;
    padding: 100px 0 60px 0;
    display: flex;
    justify-content: center;
    background: ${colors.brandBlackCurrant};
`;
const StyledInnerWrapper = styled('div')`
    max-width: 1140px;
    justify-content: flex-start;
`;
const StyledLogoAndSlogan = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledInnerGrid = styled('div', {
    shouldForwardProp: (prop) => prop !== "background",
})`
    margin-bottom: 30px;
    padding-right: 10px;
    padding-left: 10px;
    background: ${(props) => props.background};
    text-align: left;
    @media (max-width: 900px) {
        text-align: center;
    }
`;
const StyledLogo = styled('img')`
    width: 80%;
    height: 100%;
    max-width: 550px;
    text-align: left;
`;
const StyledSlogan = styled('p')`
    font-weight: 600;
    color: ${colors.brandSalmon};
    text-align: left;
    font-size: 1.2rem;
    padding: 0px;
    margin: 0px;
`;
