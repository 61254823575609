import { styled } from "@mui/material/styles";
import colors from "../../styles/colors";
import { Button } from "@mui/material";

export const StyledButton = styled(Button, {
    shouldForwardProp: (prop) =>
        prop !== "zIndex" && prop !== "justifySelf" && prop !== "gridArea" && prop !== "alignSelf",
})`
    ${(props) => (props.gridArea ? `grid-area: ${props.gridArea}` : "")};
    ${(props) => (props.zIndex ? `z-index: ${props.zIndex}` : "")};
    ${(props) =>
        props.justifySelf
            ? `justify-self: ${props.justifySelf}`
            : "justify-self: center"};
    ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf}` : "")};
    height: 48px;
    width: 200px;
    background: rgb(242, 239, 235, 0.7) !important;
    border-radius: 999em !important;
    border-color: ${colors.brandGreen} !important;
    color: ${colors.mainHeaderGray} !important;
    margin: 0 0px !important;
    font-size: 15px !important;
    font-family: Poppins !important;
    line-height: 3.2rem !important;
    &:hover {
        background: ${colors.grayLight} !important;
    }
    @media (max-width: 768px) {
        font-size: 0.9rem !important;
        height: 40px;
        width: 180px;
        margin: 6px 10px !important;
    }
    @media (max-width: 500px) {
        font-size: 0.8rem !important;
        height: 40px;
        width: 180px;
        margin: 6px 10px !important;
    }
`;
