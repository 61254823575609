import React, { useEffect } from "react";
import { authInstance } from "../config/Fire.js";
import axios from "axios";
import {
    onAuthStateChanged,
    isSignInWithEmailLink,
    signInWithEmailLink,
    browserLocalPersistence,
    setPersistence,
} from "firebase/auth";
import { useQuery } from "@tanstack/react-query";
import { isAdminUser } from "../api";
import { useUserStore } from "../hooks/useUserStore.js";

const auth = authInstance;

export const useHandleLogin = () => {
    const { setUser, setIsAdmin, loggedIn } = useUserStore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (_user) => {
            if (_user) {
                const token = await _user.getIdToken();
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${token}`;
                setUser(_user);
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                if (typeof window === "undefined") {
                    return;
                }
                // Existing and future Auth states are now persisted in the current local storage
                if (isSignInWithEmailLink(auth, window.location.href)) {
                    // Additional state parameters can also be passed via URL.
                    // This can be used to continue the user's intended action before triggering
                    // the sign-in operation.
                    // Get the email if available. This should be available if the user completes
                    // the flow on the same device where they started it.
                    // The client SDK will parse the code from the link for you.
                    const signInEmail =
                        window.localStorage.getItem("emailForSignIn") ||
                        prompt(
                            "Kirjoita sähköpostisi, jotta pääset kirjautumaan ParempiaTekoja -sivulle."
                        );

                    signInWithEmailLink(auth, signInEmail, window.location.href)
                        .then((result) => {
                            // Clear email from storage.
                            window.localStorage.removeItem("emailForSignIn");
                            // You can access the new user via result.user
                            // Additional user info profile not available via:
                            // result.additionalUserInfo.profile == null
                            // You can check if the user is new or existing:
                            // result.additionalUserInfo.isNewUser
                        })
                        .catch((error) => {
                            console.error(error);
                            // Some error occurred, you can inspect the code: error.code
                            // Common errors could be invalid email and invalid or expired OTPs.
                        });
                }
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }, []);

    const { data: isAdmin } = useQuery({
        queryKey: ["isAdminUser"],
        queryFn: isAdminUser,
        enabled: !!loggedIn,
        retry: false,
    });

    useEffect(() => {
        if (isAdmin) {
            setIsAdmin(isAdmin);
        }
    }, [isAdmin]);
};
