import pick from "lodash/pick";

export const cleanDocument = (doc) => {
    return pick(doc, [
        "id",
        "owners",
        "charityType",
        "amount",
        "created",
        "goal_amount",
        "minimum_amount",
        "due_date",
        "description",
        "charity_name",
        "organizer",
        "name",
        "company",
        "business_id",
        "start_date",
        "thank_you_message",
        "email",
        "editor",
        "image",
        "hidden",
        "accepted",
        "status",
        "phone",
        "edited",
        "license_num",
        "amount_donated",
        "purchaseItems",
        "stripeAccountId",
        "applicationFee",
        "slugUrlValue",
        "sortStartValue",
        "sortEndValue",
    ]);
};
