window.addEventListener('DOMContentLoaded', (event) => {
    window.onload = function() {
        setTimeout(function() {

            let script = document.createElement('script');
            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11402963667";
            document.body.append(script);

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "AW-11402963667");
        }, 5000);
    };
});