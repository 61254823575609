import React, { useState, useMemo } from "react";
import { getBlogs } from "../api";
import { useQuery } from "@tanstack/react-query";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import colors from "../styles/colors";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton, Fade } from "@mui/material";
import { StyledMainPageBigText } from "./common/Text";
import Skeleton from "@mui/material/Skeleton";
import { useInView } from "react-intersection-observer";

export const placeholderImg =
    "https://blackdeerfestival.com/wp-content/uploads/2017/09/Blog-Post-Holder.jpg";

const BlogPlaceHolder = ({ index }) => {
    return (
        <Grid
            xs={12}
            sm={6}
            md={4}
            lg={4}
            item
            sx={{
                "@media (max-width: 600px)": {
                    ...(index !== 0 && {
                        display: "none",
                    }),
                },
            }}
        >
            <Grid container justifyContent="center">
                <Skeleton variant="rectangular" width={400} height={500} />
            </Grid>
        </Grid>
    );
};

const BlogPlaceHolders = ({ maxItems }) => {
    const items = [];
    for (let index = 0; index < maxItems; index++) {
        items.push(
            <BlogPlaceHolder key={`${index}-blog-skeleton`} index={index} />
        );
    }
    return items;
};

export const BlogPreviewItem = ({ slug, feature_image, title, excerpt, index, blogPage = false }) => {
    return (
        <Grid
            xs={12}
            sm={6}
            md={4}
            lg={4}
            item
            key={slug}
            sx={{
                "@media (max-width: 600px)": {
                    ...(!blogPage && index !== 0 && {
                        display: "none",
                    }),
                },
            }}
        >
            <Grid container justifyContent="center">
                <Grid item>
                    <Fade in timeout={1000} unmountOnExit>
                        <Card
                            sx={{
                                margin: "10px",
                                transition: "1s ease-in-out",
                                boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
                                borderRadius: "10px",
                                borderStyle: "solid",
                                borderWidth: 0,
                                fontFamily: "Poppins, sans-serif",
                                maxWidth: 400,
                                height: 500,
                                background: colors.white,
                            }}
                        >
                            <CardActionArea
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    justifyContent: "flex-start",
                                }}
                                href={`/blog/${slug}`}
                            >
                                <CardMedia
                                    component="img"
                                    alt="img"
                                    height="50%"
                                    image={feature_image || placeholderImg}
                                    title={title}
                                />

                                <StyledCardContent>
                                    <StyledTitle>{title}</StyledTitle>
                                    <StyledContent>{excerpt}</StyledContent>
                                </StyledCardContent>
                            </CardActionArea>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    );
};

const BlogPreview = () => {
    const { ref, inView, entry } = useInView({});
    const {
        isLoading,
        isError,
        data: blogs = [],
        error,
    } = useQuery({ queryKey: ["blogs"], queryFn: getBlogs, enabled: !!inView });
    const { meta, posts = [] } = blogs;
    const maxItems = 3;

    //const [selectedPosts, setSelectedPosts] = useState([]);
    const [indexes, setIndexes] = useState([0, maxItems]);

    /*
    useEffect(() => {
        setSelectedPosts(posts.slice(indexes[0], indexes[1]));
    }, [posts, indexes]);
    */

    const selectedPosts = useMemo(() => {
        return posts.slice(indexes[0], indexes[1]);
    }, [posts, indexes]);

    const goLeft = () => {
        const first = Math.max(indexes[0] - 1, 0);
        const second = Math.max(indexes[1] - 1, maxItems);
        setIndexes([first, second]);
    };

    const goRight = () => {
        const first = Math.min(indexes[0] + 1, posts.length - maxItems);
        const second = Math.min(indexes[1] + 1, posts.length);
        setIndexes([first, second]);
    };

    return (
        <StyledContainer id="blog" ref={ref}>
            <StyledMainPageBigText>BLOGI</StyledMainPageBigText>
            <StyledWrapper>
                <StyledButtonRow>
                    <StyledButtonContainer>
                        <IconButton
                            aria-label="Prevous blog post"
                            sx={{
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderColor: colors.brandGreen,
                                margin: "10px",
                                "&:hover": {
                                    background: `${colors.grayBackgroundButtonColor} !important`,
                                },
                            }}
                            onClick={() => goLeft()}
                            size="large"
                        >
                            <ChevronLeftIcon
                                style={{ fill: colors.mainHeaderGray }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderColor: colors.brandGreen,
                                margin: "10px",
                                "&:hover": {
                                    background: `${colors.grayBackgroundButtonColor} !important`,
                                },
                            }}
                            aria-label="Next blog post"
                            onClick={() => goRight()}
                            size="large"
                        >
                            <ChevronRightIcon
                                style={{ fill: colors.mainHeaderGray }}
                            />
                        </IconButton>
                    </StyledButtonContainer>
                </StyledButtonRow>
                <Grid container spacing={4}>
                    {!selectedPosts?.length ? (
                        <BlogPlaceHolders maxItems={maxItems} />
                    ) : (
                        selectedPosts.map((post, index) => {
                            const {
                                published_at,
                                title,
                                feature_image, // add placeholder image if does not exist
                                excerpt,
                                slug,
                            } = post;
                            // does not list all on when width less than 800px, 2 hidden in the end
                            return (
                                <BlogPreviewItem
                                    slug={slug}
                                    feature_image={feature_image}
                                    excerpt={excerpt}
                                    title={title}
                                    index={index}
                                    key={slug}
                                />
                            );
                        })
                    )}
                </Grid>
                <StyledBlogLink
                    href="/blog"
                    target="_blank"
                >
                    {"NÄYTÄ KAIKKI BLOGIT >"}
                </StyledBlogLink>
            </StyledWrapper>
        </StyledContainer>
    );
};

export default BlogPreview;

const StyledContainer = styled("div")`
    max-width: 1800px;
    padding: 100px 50px 20px 50px;
    justify-content: center;
    margin: auto;
    display: grid;
    grid-template-areas:
        "bigtext"
        "blogs";
`;

const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50%;
    text-align: left;
    overflow: hidden;
`;

const StyledTitle = styled("p")`
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #3e4f58;
    font-family: poppins;
`;

const StyledContent = styled("p")`
    position: relative;
    font-weight: 400;
    font-size: 15px;
    font-family: poppins;
    line-height: 22px;
    color: #3e4f58;
    overflow: hidden;
    margin: 0;
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        bottom: 0px;
        left: 0px;
        height: 80px;
        background: linear-gradient(
            rgba(242, 239, 235, 0),
            rgba(255, 255, 255, 1)
        );
    }
`;

const StyledWrapper = styled("div")`
    grid-area: blogs;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    max-width: 1140px;
    margin-bottom: 50px;

    @media only screen and (max-width: 800px) {
        padding: 0 5px;
    }
    @media only screen and (max-width: 500px) {
        padding: 0;
    }
`;

const StyledButtonRow = styled("div")`
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin-bottom: 20px;
`;

const StyledButtonContainer = styled("div")``;

const StyledBlogLink = styled("a")`
    width: 100%;
    text-align: end;
    padding: 20px 20px 0px 0px;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: normal;
    color: ${colors.brandGreen};
    &:hover {
        text-decoration: underline;
        color: ${colors.brandGreen};
    }
    &:visited {
        text-decoration: none;
        color: ${colors.brandGreen};
    }
`;
