import { styled } from "@mui/material/styles";
import colors from "../../styles/colors";


export const StyledMainPageBigText = styled('div')`
    grid-area: bigtext;
    font-weight: 600;
    font-size: 32px;
    color: ${colors.mainGray};
    @media (max-width: 470px) {
        font-size: 28px;
    }
`

export const StyledMainPageTitle = styled('div')`
    grid-area: title;
    font-weight: 600;
    font-size: 1.2rem;
    color: ${colors.mainGray};
    align-self: end;
    @media (max-width: 470px) {
        margin-top: 15px;
    }
`

export const StyledMainPageText = styled('div')`
    grid-area: description;
    color: ${colors.mainGray};
    font-weight: 400;
    font-size: 1.3rem;
    @media (max-width: 470px) {
        font-size: 1.2rem;
    }
`

export const StyledMainPageLink = styled('a')`
    grid-area: link;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.mainGray};
`