import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
// import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./createEmotionCache";
import theme from "./styles/MuiTheme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import "./initGoogleTagmanager";

const cache = createEmotionCache();

if (window?.location?.host === "parempiatekoja.fi") {
    Sentry.init({
        dsn: "https://5acfe88597084f6bad4cc10160377568@o4503913813639168.ingest.sentry.io/4503913814622208",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
    });
}

function Main() {
    return (
        <BrowserRouter>
            <CacheProvider value={cache}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App ssrData={window?.ssrData || null}/>
                </ThemeProvider>
            </CacheProvider>
        </BrowserRouter>
    );
}

const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <Main />);
} else {
    const root = createRoot(rootElement);
    root.render(<Main />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
