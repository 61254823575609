import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import DialogMenuButton from "../layout/appBar/DialogMenuButton";
import colors from "../../styles/colors";

const mainMenuItems = [
    { id: 1, href: "/", label: "Etusivulle" },
    // { id: 2, href: "/#smallfunds", label: "Pienkeräykset" },
    // { id: 3, href: "/#crowdfunding", label: "Joukkorahoitukset" },
    { id: 2, href: "/luo-kampanja", label: "Aloita keräys" },
    { id: 3, href: "/pienkeraykset", label: "Lahjoita" },
    { id: 4, href: "/joukkorahoitukset", label: "Rahoita" },
    { id: 5, href: "/#about", label: "Meistä" },
    { id: 6, href: "/#questions", label: "Usein kysyttyä" },
    { id: 7, href: "/blog", label: "Blogi" },
    { id: 8, href: "/#contact", label: "Yhteystiedot" },
];

export default function PopUpNav() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <DialogMenuButton
                toggleMenu={handleClick}
                menuOpen={Boolean(anchorEl)}
            />

            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                slots={{
                    backdrop: null
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    ".MuiMenu-paper": {
                        minWidth: 180,
                        border: "1px solid #d3d4d5",
                        marginTop: "20px",
                        background: "rgba(255,255,255,0.95)",
                    },
                }}
            >
                {mainMenuItems.map((item) => {
                    return (
                        <MenuItem key={item.label} onClick={handleClose}>
                            <StyledHref href={item.href}>
                                {item.label}
                            </StyledHref>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
const StyledHref = styled("a")`
    width: 100%;
    font-family: "Poppins", "Lato", sans-serif;
    text-transform: uppercase;
    color: ${colors.mainHeaderGray};
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    text-decoration: none;
    &:hover {
        color: ${colors.brandGreen};
    }
`;
