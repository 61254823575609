import { create } from 'zustand'

export const useCampaignStore = create((set) => ({
    fundRequests: [],
    crowdfunding: [],
    crowdfundingReady: false,
    fundRequestsReady: false,
    setFundRequests: (fundRequests) => set(({ fundRequests, fundRequestsReady: true })),
    setCrowdfunding: (crowdfunding) => set({ crowdfunding, crowdfundingReady: true  }),
}))
