import React, { memo } from "react";
import { styled } from '@mui/system';
import colors from "../styles/colors";
import aboutUs from "../images/about_us.svg";
import {
    StyledMainPageTitle,
    StyledMainPageText,
    StyledMainPageLink,
    StyledMainPageBigText,
} from "./common/Text";

export const About = memo(() => {
    return (
        <StyledContainer id="about">
            <StyledGrid>
                <StyledImageContainer>
                    <img
                        src={aboutUs}
                        alt="Ojentuneet kädet joissa on Parempia Tekoja sydänlogo kämmenellä"
                    />
                </StyledImageContainer>
                <StyledMainPageTitle>MEISTÄ</StyledMainPageTitle>
                <StyledMainPageBigText>
                    Lahjoitus- ja rahoituspalvelu pienkeräyksille ja
                    joukkorahoituksille
                </StyledMainPageBigText>
                <StyledMainPageText>
                    {`Löydä oma tapasi auttaa. Voit vertailla eri lahjoitus- ja rahoituskohteita. Vaihtoehtoisesti voit perustaa oman pienkeräyksen mieleisellesi kohteelle tai vastikepohjaisen joukkorahoituskampanjan projektillesi. `}
                    <StyledMainPageLink href="/blog/meista">
                        LUE LISÄÄ
                    </StyledMainPageLink>
                </StyledMainPageText>
            </StyledGrid>
        </StyledContainer>
    );
})

const StyledGrid = styled('div')`
    max-width: 1140px;
    margin: auto;
    display: grid;
    justify-content: center;
    grid-template-rows: auto auto auto;
    grid-template-columns: 50% 50%;
    padding: 0px 0px 20px 0px;
    grid-gap: 5px 10px;
    grid-template-areas:
        "image title"
        "image bigtext"
        "image description";
    @media (max-width: 1000px) {
        grid-template-columns: 100%;
        justify-items: center;
        text-align: center;
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description";
        grid-template-rows: auto auto auto auto;
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: 470px) {
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description";
        grid-template-rows: auto auto auto auto;
        padding-left: 50px;
        padding-right: 50px;
    }
`;

const StyledImage = styled('img')`
    grid-area: image;
    max-width: 500px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
`;

const StyledImageContainer = styled('div')`
    grid-area: image;
    position: relative;
    aspect-ratio: 212 / 150;
    max-width: 500px;
    width: 100%;
    height: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 30px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const StyledContainer = styled('div')`
    width: 100%;
    background: ${colors.brandGray};
    padding-top: 50px;
    padding-bottom: 50px;
`;
