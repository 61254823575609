import React from "react";
import { styled } from "@mui/material/styles";
import colors from "../../../styles/colors.js";

const DialogMenuButton = (props) => {
    const { menuOpen, toggleMenu } = props;
    return (
        <StyledWrapper onClick={toggleMenu}>
            <StyledMenuIcon
                menuOpen={menuOpen ? "true" : null}
                id="menu_icon"
            >
                <div></div>
            </StyledMenuIcon>
        </StyledWrapper>
    );
};

export default DialogMenuButton;

const transitionDurations = "300ms";
const StyledWrapper = styled('div')`
    margin-right: 10px;
    cursor: pointer;
    .open {
        ::before {
            transform: translateY(7px) rotate(135deg);
        }
        > div {
            transform: scale(0);
        }
        ::after {
            transform: translateY(-7px) rotate(-135deg);
        }
    }
`;
const StyledMenuIcon = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== "menuOpen",
})`
    margin: 20px;
    width: 25px;
    ::before {
        border-radius: 3px;
        content: '';
        display: block;
        height: 2px;
        margin: 0 0 5px;
        transition: transform ${transitionDurations} ease-in-out;
        background: ${colors.brandGreen};
        width: 100%;
        transform: ${(props) =>
            props.menuOpen ? "translateY(7px) rotate(135deg)" : null};
    }
    > div {
        border-radius: 3px;
        content: '';
        display: block;
        height: 2px;
        margin: 5x 0;
        transition: transform ${transitionDurations} ease-in-out;
        background: ${colors.brandGreen};
        transform: ${(props) => (props.menuOpen ? " scale(0)" : null)};
    }
    ::after {
        border-radius: 3px;
        content: '';
        display: block;
        height: 2px;
        margin: 5px 0 0;
        transition: transform ${transitionDurations} ease-in-out;
        background: ${colors.brandGreen};
        width: 100%;
        transform: ${(props) =>
            props.menuOpen ? "translateY(-7px) rotate(-135deg)" : null};
`;
