import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const MuiTheme = createTheme({
    typography: {
        fontFamily: ["Poppins"]
    },
    palette: {
        primary: {
            main: "#73BF9C",
        },
        secondary: {
            main: colors.honeySuckle,
        },
    },
    components: {
        typography: {
            useNextVariants: true,
            fontFamily: "'Poppins', sans-serif",
        },
        MuiBackdrop: {
            styleOverrides: {
                root: { backgroundColor: "#f2efeb;" },
                invisible: { backgroundColor: "#f2efeb00;" },
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#fff",
                    color: colors.mainGray,
                    fontSize: 12,
                    border: `1px solid ${colors.mainGray}`,
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: "none",
                    fontSize: "1.3rem",
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    padding: "1px",
                    color: "#73BF9C",
                    "&$checked": {
                        transform: "translateX(16px)",
                        color: "#73BF9C",
                    },
                    "&$focusVisible $thumb": {
                        color: "#73BF9C",
                        border: "6px solid #fff",
                    },
                },
                root: {
                    width: 42,
                    padding: 0,
                    margin: "5px",
                },
                track: {
                    border: `1px solid #000000a3`,
                    borderRadius: "30px",
                    opacity: 1,
                    height: 28,
                    backgroundColor: "white",
                },
                thumb: {
                    paddingTop: 2,
                    width: 25,
                    height: 25,
                    boxShadow: "none",
                },
            }
        },
    },
});

export default MuiTheme;
