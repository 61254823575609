import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { getCampaignState } from "./utils";
import { CampaignBankAccountState } from './CampaignBankAccountState';
import { useCampaignStore } from '../hooks/useCampaignStore';

const ExistingCrowdfundingsList = (props) => {
    const { crowdfunding } = useCampaignStore();

    return (
        <TableContainer component={Paper} style={{ marginBottom: "30px" }}>
            <Table aria-label='simple-table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>Nimi</TableCell>
                        <TableCell style={{ width: '20%' }} align='center'>Tila</TableCell>
                        <TableCell style={{ width: '20%' }} align='center'>Kampanjatili luotu</TableCell>
                        <TableCell style={{ width: '20%' }} align='center'>Linkki muokkaukseen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {crowdfunding?.length 
                        ? crowdfunding.map((campaign) => {
                            return (
                            <TableRow key={campaign.id} sx={{ '&last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{campaign.charity_name}</TableCell>
                                <TableCell align='center'>{getCampaignState(campaign,"crowdfunding")}</TableCell>
                                <TableCell align='center'>{CampaignBankAccountState(campaign)}</TableCell>
                                <TableCell align='center' component='a' href={`/joukkorahoitus/${campaign.id}/muokkaa`}>Muokkaa</TableCell>
                            </TableRow>
                            );
                            })
                        : null}
                    </TableBody>
            </Table>
        </TableContainer>
    );
};
export default ExistingCrowdfundingsList;