import React, { useEffect } from "react";
import { getMyDocuments } from "../api";
import { useQuery } from "@tanstack/react-query";
import { useCampaignStore } from "../hooks/useCampaignStore.js";
import { useUserStore } from "../hooks/useUserStore";

export const useLoadCampaigns = () => {
    const { user } = useUserStore();
    const uid = user?.uid;
    const { setFundRequests, setCrowdfunding } = useCampaignStore();

    const { data: crowdfunding = [], isSuccess: crowdfundingIsSuccess } =
        useQuery({
            queryKey: ["my-crowdfunding"],
            queryFn: () => getMyDocuments("crowdfunding", uid),
            enabled: !!uid,
        });

    const { data: fundRequests = [], isSuccess: fundRequestsisSuccess } =
        useQuery({
            queryKey: ["my-fundRequests"],
            queryFn: () => getMyDocuments("fundRequests", uid),
            enabled: !!uid,
        });

    useEffect(() => {
        if (crowdfundingIsSuccess) {
            setCrowdfunding(crowdfunding);
        }
    }, [crowdfunding?.length, crowdfundingIsSuccess]);

    useEffect(() => {
        if (fundRequestsisSuccess) {
            setFundRequests(fundRequests);
        }
    }, [fundRequests?.length, fundRequestsisSuccess]);
};
