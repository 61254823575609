import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import colors from "../styles/colors";
import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FacebookIcon, LinkedinIcon, EmailIcon } from "react-share";


const FooterModals = () => {

    const year = useMemo(() => {
        return new Date().getFullYear()
    }, [])

    return (
        <StyledFlex>
            <StyleMainDivider>
                <StyledMenuItemPara>
                    &copy;{`${year} Parempia Tekoja Oy`}
                    <StyledDivider>|</StyledDivider>
                </StyledMenuItemPara>
                <StyledLink href="/kayttoehdot">Käyttöehdot</StyledLink>
                <StyledDivider>|</StyledDivider>
                <StyledLink href="/tietosuoja">
                    Tietosuoja- ja rekisteriseloste
                </StyledLink>
            </StyleMainDivider>
            <StyleMainDivider>
                <StyledSocialWrapper>
                    <StyledSocial>
                        <IconButton
                            href="https://www.facebook.com/parempiatekoja.fi"
                            aria-label="Facebook link"
                            sx={{
                                ".MuiIconButton-root": {
                                    backgroundColor: "transparent",
                                    height: 36,
                                    width: 36,
                                    color: colors.mainHeaderGray,
                                    padding: 0,
                                },
                                ".MuiIconButton-label": {
                                    width: 36,
                                },
                            }}
                            disableFocusRipple
                            disableRipple
                            size="large"
                        >
                            <FacebookIcon size={36} />
                        </IconButton>
                    </StyledSocial>
                    <StyledSocial>
                        <IconButton
                            href="https://www.instagram.com/parempiatekoja"
                            aria-label="Instagram link"
                            sx={{
                                ".MuiIconButton-root": {
                                    backgroundColor: "transparent",
                                    height: 36,
                                    width: 36,
                                    color: colors.mainHeaderGray,
                                    padding: 0,
                                },
                                ".MuiIconButton-label": {
                                    width: 36,
                                },
                            }}
                            disableFocusRipple
                            disableRipple
                            size="large"
                        >
                            <InstagramIcon style={{ width: 36, height: 36, color: colors.brandRed }} />
                        </IconButton>
                    </StyledSocial>
                    <StyledSocial>
                        <IconButton
                            href="https://www.linkedin.com/company/parempia-tekoja/"
                            aria-label="Linkedin link"
                            sx={{
                                ".MuiIconButton-root": {
                                    backgroundColor: "transparent",
                                    height: 36,
                                    width: 36,
                                    color: colors.mainHeaderGray,
                                    padding: 0,
                                },
                                ".MuiIconButton-label": {
                                    width: 36,
                                },
                            }}
                            disableFocusRipple
                            disableRipple
                            size="large"
                        >
                            <LinkedinIcon size={36} round />
                        </IconButton>
                    </StyledSocial>
                    <StyledSocial>
                        <IconButton
                            href="mailto: info@parempiatekoja.fi"
                            aria-label="Email us link"
                            sx={{
                                ".MuiIconButton-root": {
                                    backgroundColor: "transparent",
                                    height: 36,
                                    width: 36,
                                    color: colors.mainHeaderGray,
                                    padding: 0,
                                },
                                ".MuiIconButton-label": {
                                    width: 36,
                                },
                            }}
                            disableFocusRipple
                            disableRipple
                            size="large"
                        >
                            <EmailIcon size={36} round />
                        </IconButton>
                    </StyledSocial>
                </StyledSocialWrapper>
            </StyleMainDivider>
        </StyledFlex>
    );
};
export default FooterModals;

const StyledSocialWrapper = styled('div')`
    display: flex;
    align-items: left;
    justify-content: left;
`;

const StyledLink = styled('a')`
    display: flex;
    align-items: center;
    color: ${colors.white};
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    font-size: 12px;
    margin: 0;
    @media (max-width: 470px) {
        text-align: center;
    }
`;

const StyleMainDivider = styled('span')`
    display: flex;
    margin-left: 2%;
    margin-right: 2%;
`;
const StyledFlex = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1900px;
    flex-wrap: wrap;
    @media (max-width: 800px) {
        justify-content: center;
    }
`;
const StyledSocial = styled('div')`
    display: flex;
    margin-left: 5px;
    align-items: right;
`;

const StyledMenuItemPara = styled('p')`
    display: flex;
    align-items: center;
    color: ${colors.white} !important;
    font-size: 12px;
    margin: 0;
    &:hover {
        color: ${colors.teal};
    }
    @media (max-width: 470px) {
        text-align: center;
    }
`;

const StyledDivider = styled('span')`
    display: flex;
    align-items: center;
    padding: 0 5px;
    color: ${colors.white};
`;
