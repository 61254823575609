import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import { StyledButton } from "./common/Button";
import {
    StyledMainPageBigText,
    StyledMainPageTitle,
    StyledMainPageText,
    StyledMainPageLink,
} from "./common/Text";
import smallFundImage from "../images/small_fund_brand_colors.svg";
import crowdfundingImage from "../images/crowdfunding_brand_colors.svg";

export const Features = memo(() => {
    return (
        <StyledCenter id="features">
            <StyledGrid id="smallfunds">
                <StyledMainPageTitle>PIENKERÄYKSET</StyledMainPageTitle>
                <StyledMainPageBigText>
                    Lahjoita tai aloita oma pienkeräyksesi
                </StyledMainPageBigText>
                <StyledMainPageText>
                    {`Vertaile eri pienkeräyksiä ja tee lahjoitus haluamaasi kohteeseen. Voit luoda oman pienkeräyksen ja kerätä lahjoituksia jopa 10 000€ asti! `}
                    <br></br>
                    <StyledMainPageLink href="/blog/mika-on-pienkerays">
                        LUE LISÄÄ
                    </StyledMainPageLink>
                </StyledMainPageText>
                <StyledImageContainer justifySelf="left">
                    <img
                        src={smallFundImage}
                        alt="Kädet lahjoittavat kolikoita keräysastiaan"
                    />
                </StyledImageContainer>

                <StyledButtons>
                    <StyledLeftButton href="/pienkeraykset">
                        LAHJOITA
                    </StyledLeftButton>
                    <StyledLeftButton href="/luo-kampanja">
                        ALOITA KERÄYS
                    </StyledLeftButton>
                </StyledButtons>
            </StyledGrid>
            <StyledReverseGrid id="crowdfunding">
                <StyledMainPageTitle>JOUKKORAHOITUKSET</StyledMainPageTitle>
                <StyledMainPageBigText>
                    Rahoita tai aloita oma joukkorahoitus
                </StyledMainPageBigText>
                <StyledMainPageText>
                    {`Vertaile eri vastikepohjaisia joukkorahoituksia, rahoita suosikkihankettasi tai aloita oma vastikkeellinen joukkorahoituskampanjasi! `}
                    <br></br>
                    <StyledMainPageLink href="/blog/aloita-joukkorahoitus">
                        LUE LISÄÄ
                    </StyledMainPageLink>
                </StyledMainPageText>
                <StyledImageContainer justifySelf="left">
                    <img
                        src={crowdfundingImage}
                        alt="Kädet rahoittavat seteleitä joukkorahoitukseen"
                    />
                </StyledImageContainer>
                <StyledButtons>
                    <StyledLeftButton href="/joukkorahoitukset">
                        RAHOITA
                    </StyledLeftButton>
                    <StyledLeftButton href="/luo-kampanja">
                        ALOITA KAMPANJA
                    </StyledLeftButton>
                </StyledButtons>
            </StyledReverseGrid>
        </StyledCenter>
    );
})


const StyledCenter = styled('div')``;

const StyledGrid = styled('div')`
    max-width: 1140px;
    margin: auto;
    margin-bottom: 50px;
    display: grid;
    justify-content: center;
    grid-template-rows: 8% 27% 50% 15%;
    grid-template-columns: 50% 50%;
    padding: 100px 0px 20px 0px;
    grid-gap: 5px;
    grid-template-areas:
        "image title"
        "image bigtext"
        "image description"
        "image buttons";
    @media (max-width: 1000px) {
        grid-template-columns: 100%;
        justify-items: center;
        text-align: center;
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description"
            "buttons";
        grid-template-rows: auto auto auto auto auto;
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: 470px) {
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description"
            "..."
            "buttons";
        grid-template-rows: auto auto auto auto 20px auto;
        padding-left: 50px;
        padding-right: 50px;
    }
`;

const StyledReverseGrid = styled(StyledGrid)`
    max-width: 1140px;
    justify-content: center;
    margin: auto;
    display: grid;
    grid-template-rows: auto auto auto 70px;
    grid-template-columns: 50% 50%;
    padding: 100px 0px 80px 0px;
    grid-gap: 5px;
    grid-template-areas:
        "title image"
        "bigtext image"
        "description image"
        "buttons image";
    @media (max-width: 1000px) {
        grid-template-columns: 100%;
        justify-items: center;
        text-align: center;
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description"
            "buttons";
        grid-template-rows: auto auto auto auto auto;
    }
    @media (max-width: 470px) {
        grid-template-areas:
            "image"
            "title"
            "bigtext"
            "description"
            "..."
            "buttons";
        grid-template-rows: auto auto auto auto 20px auto;
        padding: 50px 50px 100px 50px;
    }
`;

const StyledImageContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== "justifySelf",
})`
    grid-area: image;
    position: relative;
    aspect-ratio: 212 / 150;
    width: 100%;
    height: 100%;
    max-width: 500px;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 500px;
        object-fit: cover;
        justify-self: center;
        border-radius: 30px;
        ${(props) =>
            props.justifySelf ? `justify-self: ${props.justifySelf};` : ""};
        @media (max-width: 1000px) {
            justify-self: unset;
        }
    }
`;


const StyledLeftButton = styled(StyledButton)`
    justify-self: left;
    align-self: center;
    @media (max-width: 470px) {
        justify-self: center;
    }
`;

const StyledButtons = styled('div')`
    grid-area: buttons;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    @media (max-width: 1000px) {
        justify-content: center;
    }
`;
