import { v4 as uuidv4 } from "uuid";
import reduce from "lodash/reduce";
import isEqual from "lodash/isEqual";
import defaultImage from "../images/PT_in_two_rows_red_O.png";

function getMockDate(val) {
    const d = new Date();
    d.setDate(d.getDate() + val);
    return d;
}

function dayTillDueDate(dueDate, today = new Date()) {
    return Math.round((dueDate - today) / (24 * 3600 * 1000));
}

export function getDateProgress(startDate, endDate) {
    const dueDate = endDate ? new Date(endDate) : getMockDate(15);
    const start = startDate ? new Date(startDate) : getMockDate(-45);
    const daysUntilDue = dayTillDueDate(dueDate);
    const totalDays = dayTillDueDate(dueDate, start);
    const dateProgress = Math.round(
        ((totalDays - daysUntilDue) / totalDays) * 100
    );
    return [daysUntilDue, dateProgress, dueDate, start];
}

export const getTags = () => {
    return [
        "Koulutus",
        "Terveys",
        "Kehitysapu",
        "Uskonto",
        "Eläimet",
        "Ympäristö",
        "Ihmisoikeudet",
        "Lapset ja nuoret",
        "Mielenterveys",
        "Liikunta ja urheilu",
        "Taide ja kulttuuri",
        "Eläkeläiset ja veteraanit",
    ];
};

export const showInEuros = (value) => {
    return new Intl.NumberFormat("fi-FI", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(Math.round(value / 100));
};

export const toEuros = (value) => {
    return value / 100;
};

export function shareUrl(charityType, charityID, ssrOrigin = "") {
    let origin = ssrOrigin;
    if (typeof window !== "undefined") {
        origin = window.location.origin;
    }
    if (!origin) {
        return "";
    }
    switch (charityType) {
        case "fundRequests":
            return `${origin}/pienkerays/${charityID}`;
        case "crowdfunding":
            return `${origin}/joukkorahoitus/${charityID}`;
        default:
            return `${origin}`;
    }
}

export function shareText(charityType) {
    switch (charityType) {
        case "fundRequests":
            return `Hei, tämä rahankeräys tarvitsee lahjoittajia. Mukaan voi lähteä valitsemallaan summalla!`;
        case "crowdfunding":
            return `Tämä joukkorahoitus saattaisi kiinnostaa sinua. Lähde mukaan rahoittamaan!`;
        default:
            if (typeof window === "undefined") {
                return "";
            }
            return `${window.location.origin}`;
    }
}

export const RESERVE_KEY = uuidv4();

export function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function isOwner(user, owners) {
    return (owners || []).includes(user?.uid);
}

export function isMobileDevice() {
    if (typeof window === "undefined") {
        return;
    }
    let check = false;
    (function (a) {
        if (
            typeof a === "string" &&
            (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substring(0, 4)
                ))
        )
            check = true;
    })(
        window?.navigator?.userAgent ||
            window?.navigator?.vendor ||
            window?.opera
    );
    return check;
}

export const DEFAULT_IMAGE = defaultImage;

export const getCommonThankYouMessage = ({
    capitalizedName,
    amount,
    currency,
    charityName,
    charityTypeShort,
}) => {
    const supportString =
        charityTypeShort === "cf" ? "rahoituksestanne" : "lahjoituksestasi";
    const commonpart = `Kiitos ${capitalizedName} ${supportString} kohteeseen ${charityName}.`; //${amount} ${currency}
    const commonThankYouMessage = `${commonpart} Arvostamme todella tukeasi ja se menee nyt varmasti hyvään tarkoitukseen.`;
    return commonThankYouMessage;
};

export const getCampaignState = (campaign, charityType) => {
    const _dueDate = campaign?.due_date;
    const startDate = campaign?.start_date;
    const [daysUntilDue] = getDateProgress(startDate, _dueDate);

    const amountCollected = campaign?.amount_donated || 0;
    const amountGoal = campaign?.goal_amount || 1000000;

    let isClosed = daysUntilDue < 0;
    if (charityType === "fundRequests") {
        isClosed = amountCollected > amountGoal || daysUntilDue < 0;
    }
    if (campaign?.status === "ready" && campaign?.hidden) {
        return "Odottaa hyväksymistä";
    }
    if (campaign?.status === "completed" || isClosed) {
        return "Päättynyt";
    }
    if (campaign?.hidden) {
        return "Luonnos";
    }
    return "Aktiivinen";
};

export const showDiffFields = (a, b) => {
    return reduce(
        a,
        function (result, value, key) {
            return isEqual(value, b[key]) ? result : result.concat(key);
        },
        []
    );
};
