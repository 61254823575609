import React from "react";
import { styled } from "@mui/material/styles";
import PopUpNav from "./nav/PopUpNav";
import { User } from "./User";
import { Link } from "react-router-dom";
import { isMobileDevice } from "./utils";
import { useUserStore } from "../hooks/useUserStore";
import colors from "../styles/colors";
import Fade from "@mui/material/Fade";
import logo from "../images/logo_white_O_red_2x.png";
import hearth_logo from "../images/icon_red_O_blue_small.png";
import Box from "@mui/material/Box";

const Navigation = () => {
    const { user } = useUserStore();
    const userEmail = user?.email
    return (
        <StyledNav id="menu" disblayNav>
            <StyledContainer>
                <StyledNavBarHeader>
                    <StyledHrefLogo disblayNav to="/">
                        <Fade in timeout={300}>
                            <Box>
                                <StyledLogo
                                    src={logo}
                                    alt="Parempia Tekoja logo"
                                />
                            </Box>
                        </Fade>
                    </StyledHrefLogo>
                    <StyledHrefHearthLogo to="/">
                        <Fade in timeout={300}>
                            <Box>
                                <StyledHearthLogo
                                    src={hearth_logo}
                                    alt="Parempia Tekoja sydänlogo"
                                />
                            </Box>
                        </Fade>
                    </StyledHrefHearthLogo>
                    <StyledPopUpNavWrapper>
                        <User userEmail={userEmail} />
                        <PopUpNav />
                    </StyledPopUpNavWrapper>
                </StyledNavBarHeader>
            </StyledContainer>
        </StyledNav>
    );
};

export default Navigation;

const StyledNav = styled("div", {
    shouldForwardProp: (prop) => prop !== "disblayNav",
})`
    background: ${(props) =>
        props.disblayNav ? colors.brandBlackCurrant : "transparent"} !important;
    border-bottom-color: ${colors.brandGreen};
    border-bottom-width: ${(props) => (props.disblayNav ? "5px" : "0px")};
    border-bottom-style: ${(props) => (props.disblayNav ? "solid" : "none")};
    height: 85px;
    padding: 15px 15px 15px 40px;
    transition: all 0.8s;
    top: 0;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
`;
const StyledHrefLogo = styled(Link, {
    shouldForwardProp: (prop) => prop !== "disblayNav",
})`
    color: ${(props) => (props.disblayNav ? "#555" : "#fff")} !important;
    @media (max-width: 470px) {
        display: none;
    }
`;

const StyledPopUpNavWrapper = styled("div")`
    display: flex;
    align-items: center;
`;
const StyledHrefHearthLogo = styled(Link)`
    @media (min-width: 470px) {
        display: none;
    }
`;

const StyledLogo = styled("img")`
    width: 250px;
`;

const StyledHearthLogo = styled("img")`
    width: 45px;
    height: 45px;
`;

const StyledNavBarHeader = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledContainer = styled("div")`
    margin-right: auto;
    margin-left: auto;
    display: flex;
    width: 100%;
    justify-content: center;
`;
