import React, { Suspense } from "react";
import Navigation from "./navigation.jsx";
import { Header } from "./Header.jsx";
import { About } from "./About.jsx";
import { FrequentlyAsked } from "./FrequentlyAsked.jsx";
import { Footer } from "./Footer.jsx";
import BlogPreview from "./BlogPreview.jsx";
import { Features } from "./Features.jsx";
import { CircularProgress } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useUserStore } from "../hooks/useUserStore.js";
import { HelmetMetaData } from "./cardViews/HelmetMetaData.jsx";
import { DEFAULT_IMAGE } from "./utils.js";
import { useLoadCampaigns } from "./LoadCampaigns.jsx";
import { useHandleLogin } from "./HandleLogin.jsx";
// import CardsView from "./cardViews/CardsView";
// import Charity from "./cardViews/Charity";
// import LoginView from "./LoginView";
//import TermsOfUse from "./TermsOfUse";
//import PrivacyPage from "./PrivacyPage";
//import ApplicationSent from "./ApplicationSent";
// import ChooseForm from "./editors/ChooseForm";
// import Logout from "./Logout";
const DonateResultView = React.lazy(() => import("./DonateResult.jsx"));
const Blog = React.lazy(() => import("./Blog.jsx"));
const BlogPost = React.lazy(() => import("./BlogPost.jsx"));
const TermsOfUse = React.lazy(() => import("./TermsOfUse.jsx"));
const PrivacyPage = React.lazy(() => import("./PrivacyPage.jsx"));
const ApplicationSent = React.lazy(() => import("./ApplicationSent.jsx"));
const ChooseForm = React.lazy(() => import("./editors/ChooseForm.jsx"));
const Logout = React.lazy(() => import("./Logout.jsx"));
const LoginView = React.lazy(() => import("./LoginView.jsx"));
const CardsView = React.lazy(() => import("./cardViews/CardsView.js"));
const Charity = React.lazy(() => import("./cardViews/Charity.jsx"));
const CharityEditorForm = React.lazy(() =>
    import("./editors/CharityEditorForm.jsx")
);
const CrowdfundingEditorForm = React.lazy(() =>
    import("./editors/CrowdfundingEditorForm.jsx")
);

export const LandingApp = ({ ssrData }) => {
    const { loggedIn } = useUserStore();
    useLoadCampaigns();
    useHandleLogin();
    return (
        <div>
            <HelmetMetaData
                image={DEFAULT_IMAGE}
                description="Aloita oma keräys tai joukkorahoitus ilmaiseksi alustallamme. Voit myös lahjoittaa tai rahoittaa sinulle tärkeitä keräyksiä ja joukkorahoituksia."
                title="Aloita oma keräys tai joukkorahoitus | Parempia Tekoja Oy"
            />
            <Navigation />
            <Routes>
                <Route
                    path="/"
                    element={
                        <StyledWrapper>
                            <Header />
                            <Features />
                            <About />
                            <FrequentlyAsked />
                            <BlogPreview />
                            <Footer />
                        </StyledWrapper>
                    }
                ></Route>
                <Route
                    path="blog"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <Blog ssrData={ssrData} />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="blog/:slug"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <BlogPost ssrData={ssrData} />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="lahjoitus/:id"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <StyledWrapper>
                                <DonateResultView />
                            </StyledWrapper>
                        </Suspense>
                    }
                />
                <Route
                    path="luo-kampanja"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <ChooseForm />
                        </Suspense>
                    }
                />
                <Route
                    path="luo-pienkerays"
                    element={
                        !loggedIn ? (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <LoginView path="pienkerays" />
                            </Suspense>
                        ) : (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <CharityEditorForm />
                            </Suspense>
                        )
                    }
                />
                <Route
                    path="luo-joukkorahoitus"
                    element={
                        !loggedIn ? (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <LoginView path="joukkorahoitus" />
                            </Suspense>
                        ) : (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <CrowdfundingEditorForm />
                            </Suspense>
                        )
                    }
                />
                <Route
                    path="hyväntekeväisyysjärjestö/:id"
                    element={
                        <StyledWrapper>
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <Charity charityType="charities" />
                            </Suspense>
                        </StyledWrapper>
                    }
                />
                <Route
                    path="pienkerays/:id"
                    element={
                        <StyledWrapper>
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <Charity
                                    charityType="fundRequests"
                                    ssrData={ssrData}
                                />
                            </Suspense>
                        </StyledWrapper>
                    }
                />
                <Route
                    path="pienkerays/:id/muokkaa"
                    element={
                        !loggedIn ? (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <LoginView />
                            </Suspense>
                        ) : (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <CharityEditorForm editForm />
                            </Suspense>
                        )
                    }
                />
                <Route
                    path="joukkorahoitus/:id"
                    element={
                        <StyledWrapper>
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <Charity
                                    charityType="crowdfunding"
                                    ssrData={ssrData}
                                />
                            </Suspense>
                        </StyledWrapper>
                    }
                />
                <Route
                    path="joukkorahoitus/:id/muokkaa"
                    element={
                        !loggedIn ? (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <LoginView />
                            </Suspense>
                        ) : (
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <CrowdfundingEditorForm editForm />
                            </Suspense>
                        )
                    }
                />
                <Route
                    path="/pienkeraykset"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <CardsView
                                campaignType="fundRequests"
                                ssrData={ssrData}
                            />
                        </Suspense>
                    }
                />
                <Route
                    path="joukkorahoitukset"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <CardsView
                                campaignType="crowdfunding"
                                ssrData={ssrData}
                            />
                        </Suspense>
                    }
                />
                <Route
                    path="kayttoehdot"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <TermsOfUse />
                        </Suspense>
                    }
                />
                <Route
                    path="tietosuoja"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <PrivacyPage />
                        </Suspense>
                    }
                />
                <Route
                    path="hakemuslahetetty"
                    element={
                        <StyledWrapper>
                            <Suspense
                                fallback={
                                    <LoadingWrapper>
                                        <CircularProgress size={40} />
                                    </LoadingWrapper>
                                }
                            >
                                <ApplicationSent />
                            </Suspense>
                        </StyledWrapper>
                    }
                />
                <Route
                    path="login"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <LoginView path="login" />
                        </Suspense>
                    }
                />
                <Route
                    path="logout"
                    element={
                        <Suspense
                            fallback={
                                <LoadingWrapper>
                                    <CircularProgress size={40} />
                                </LoadingWrapper>
                            }
                        >
                            <Logout />
                        </Suspense>
                    }
                />
            </Routes>
        </div>
    );
};

export default LandingApp;

const LoadingWrapper = styled("div")`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledWrapper = styled("div")`
    width: 100%;
    position: absolute;
    overflow-x: hidden;
`;
