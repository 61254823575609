import React, { useCallback, useMemo } from "react";
import { enableAnalytics, doNotTrack } from "./config/Fire.js";
import LandingApp from "./components/LandingApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CookieConsent from "react-cookie-consent";
import { HelmetProvider } from "react-helmet-async";
import colors from "./styles/colors";

const declineButtonStyle={
    borderRadius: "999em",
    borderColor: colors.brandGreen,
    color: colors.mainHeaderGray,
    margin: "15px",
    background: "#fff",
    fontSize: "15px",
    height: "48px",
    width: "120px",
    fontFamily: "Sans-serif",
}

const buttonStyle={
    borderRadius: "999em",
    borderColor: colors.brandGreen,
    color: colors.mainHeaderGray,
    margin: "15px",
    background: "#74bf9c",
    fontSize: "15px",
    height: "48px",
    width: "120px",
    fontFamily: "Sans-serif",
}

const style = {
    position: "relative",
    top: 150,
    left: "50%",
    transform: "translateX(-50%)",
    justifySelf: "center",
    justifyContent: "center",
    alignItems: "center",
    background: "#3E4F58",
    textAlign: "center",
    minWidth: 250,
    maxWidth: 350,
    borderRadius: 10,
    height: 400,
    fontFamily: "Sans-serif",
    lineHeight: 1.4,
}


export const App = ({ ssrData }) => {
    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // With SSR, we usually want to set some default staleTime
                        // above 0 to avoid refetching immediately on the client
                        staleTime: 60 * 1000,
                    },
                },
            })
    );

    const memoizedssrData = useMemo(() => {
        return ssrData
    }, [ssrData])

    const memoizedEnableAnalytics = useCallback(() => {
        enableAnalytics()
    }, [])

    
    const memoizedDoNotTrack = useCallback(() => {
        doNotTrack()
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <HelmetProvider>
                <LandingApp ssrData={memoizedssrData} />
                <CookieConsent
                    buttonText="Accept"
                    cookieName="ParempiaTekojaOyCookies1"
                    overlay="true"
                    style={style}
                    buttonStyle={buttonStyle}
                    enableDeclineButton
                    onAccept={memoizedEnableAnalytics}
                    onDecline={memoizedDoNotTrack}
                    declineButtonText="Decline"
                    declineButtonStyle={declineButtonStyle}
                >
                    Notice!<br></br>We are using cookies to provide necessary
                    website functionality, improve your experience and analyze
                    our traffic. By using our website, you agree to our{" "}
                    <a href="/kayttoehdot">Terms and Conditions</a>,{" "}
                    <a href="/tietosuoja">Privacy Policy</a> and our cookies
                    usage.
                </CookieConsent>
            </HelmetProvider>
        </QueryClientProvider>
    );
};

export default App;
