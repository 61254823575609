import React, { memo } from "react";
import { Fade } from "@mui/material";
import colors from "../styles/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "./common/Button";
import logo from "../images/Logo_black_O_red.svg";
import mainImage from "../images/paakuva.svg";

export const Header = memo(() => {
    return (
        <StyledHeaderContainer>
            <Fade in timeout={800}>
                <StyledHeaderText1 color={colors.dark}>
                    Löydä oma tapasi auttaa
                </StyledHeaderText1>
            </Fade>
            <Fade in timeout={800}>
                <StyledLogoContainer>
                    <img src={logo} alt="Parempia Tekoja logo" />
                </StyledLogoContainer>
            </Fade>
            <Fade in timeout={800}>
                <StyledHeaderText2 color={colors.dark}>
                    Lahjoitus- ja rahoituspalvelu pienkeräyksille ja
                    vastikepohjaisille joukkorahoituskampanjoille
                </StyledHeaderText2>
            </Fade>
            <Fade in timeout={1200}>
                <StyledButton
                    gridArea="button1"
                    zIndex={2}
                    alignSelf="center"
                    href="/pienkeraykset"
                >
                    PIENKERÄYKSET
                </StyledButton>
            </Fade>
            <Fade in timeout={1200}>
                <StyledButton
                    gridArea="button2"
                    alignSelf="center"
                    zIndex={2}
                    href="/joukkorahoitukset"
                >
                    JOUKKORAHOITUKSET
                </StyledButton>
            </Fade>
            <StyledMainImageContainer>
                <img src={mainImage} alt="Pääkuva" />
            </StyledMainImageContainer>
        </StyledHeaderContainer>
    );
})

const StyledLogo = styled('img')`
    width: 100%;
    height: 100%;
    object-fit: contain;
    grid-area: logo;
    z-index: 2;
`;

const StyledHeaderContainer = styled('div')`
    max-width: 1140px;
    margin: auto;
    min-height: 450px;
    display: grid;
    grid-row-gap: 5px;
    justify-content: center;
    grid-template-rows: auto 50px 50px auto 70px auto;
    grid-template-columns: 20% 20% 60%;
    padding: 150px 0px 0px 0px;
    grid-template-areas:
        "... ... image"
        "text text image"
        "logo logo image"
        "description description image"
        "button1 button2 image"
        "... ... image";
    @media (max-width: 1200px) {
        grid-template-rows: auto auto auto auto;
        text-align: center;
    }
    @media (max-width: 1120px) {
        justify-items: center;
        max-width: 800px;
        padding-left: 50px;
        padding-right: 50px;
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "image image"
            "text text"
            "logo logo"
            "description description"
            "button1 button2";
        grid-template-rows: auto auto auto auto auto;
    }
    @media (max-width: 820px) {
        grid-template-columns: 100%;
        padding-left: 50px;
        padding-right: 50px;
        grid-template-areas:
            "image"
            "text"
            "logo"
            "description"
            "button1"
            "button2";
        grid-template-rows: auto auto auto auto auto auto;
    }
    @media (max-width: 500px) {
        margin-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto auto auto;
        grid-template-areas:
            "logo"
            "text"
            "image"
            "description"
            "button1"
            "button2";
    }
`;

const StyledHeaderText1 = styled('div', {
    shouldForwardProp: (prop) => prop !== "color",
})`
    font-size: 25px;
    color: ${(props) => props.color || "#3e4f58"};
    font-weight: 500;
    align-self: end;
    @media (max-width: 768px) {
        font-size: 1.8rem;
    }
    @media (max-width: 500px) {
        align-self: start;
        font-size: 1.3rem;
    }
    grid-area: text;
    z-index: 2;
`;

const StyledHeaderText2 = styled('div', {
    shouldForwardProp: (prop) => prop !== "color",
})`
    font-size: 20px;
    color: ${(props) => props.color || "#3e4f58"};
    font-weight: 400;
    @media (max-width: 850px) {
        font-size: 1.5rem;
    }
    @media (max-width: 520px) {
        font-size: 1.2rem;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    grid-area: description;
    z-index: 2;
    margin-top: 20px;
    margin-bottom: 30px;
`;

const StyledImage = styled('img')`
    grid-area: image;
    position: relative;
    justify-self: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media (max-width: 1120px) {
        left: auto;
    }
`;

const StyledMainImageContainer = styled('div')`
    grid-area: image;
    position: relative;
    justify-self: center;
    aspect-ratio: 1068 / 666;
    width: 100%;
    height: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

const StyledLogoContainer = styled('div')`
    position: relative;
    aspect-ratio: 361 / 33;
    grid-area: logo;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        z-index: 2;
    }
`