import React, { memo } from "react";
import QuestionAccordion from "./QuestionAccordion";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { StyledMainPageBigText } from "./common/Text";

const frequentlyAsked = [
    {
        id: 1,
        question: "Mikä on Parempia Tekoja?",
        answer: (
            <span>
                Parempia Tekoja on lahjoitus- ja rahoituspalvelu:<br></br>1)
                pienkeräyksille ja<br></br>2) vastikepohjaisille
                joukkorahoituskampanjoille<br></br>Voit joko perustaa oman
                pienkeräyksen, käynnistää joukkorahoituskampanjan tai toimia
                lahjoittajan/rahoittajan roolissa.
            </span>
        ),
    },
    {
        id: 2,
        question: "Kenelle palvelu on suunnattu?",
        answer: "Sivusto on suunnattu kaikille, jotka haluavat auttaa tavalla tai toisella - etsit sitten pienkeräyslahjoituskohdetta tai haluat tukea vastikepohjaista joukkorahoituskampanjaa. Voit myös hakea lahjoittajia omalle pienkeräykselle tai rahoitusta joukkorahoituskampanjalle.",
    },
    {
        id: 3,
        question: "Mitä on pienkeräys?",
        answer: (
            <span>
                Pienkeräykset ovat pieniä rahankeräyksiä, joilla järjestöt tai
                vähintään kolmen yksityishenkilön muodostamat ryhmät voivat
                kerätä rahaa yleishyödylliseen tarkoitukseen maksimissaan 10
                000€. Pienkeräyksen aloittamiseen ei vaadita lupaa, mutta
                kerääjän tulee tehdä ilmoitus keräyksestä poliisilaitokselle.
                Lue lisää pienkeräyksistä{" "}
                <a
                    href="/blog/mika-on-pienkerays/ "
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
    {
        id: 3,
        question: "Miten teen lahjoituksen pienkeräykselle?",
        answer: (
            <span>
                Voit etsiä sopivaa pienkeräyskohdetta{" "}
                <a href="/pienkeraykset" target="_blank">
                    täältä
                </a>
                . Valitse mieleisesi summa ja maksa joko pankki- tai
                luottokortilla, Google tai Apple Paylla. Älä unohda kertoa
                lahjoituskohteesta myös ystävillesi - jaa keräys omissa
                some-kanavissasi!
            </span>
        ),
    },
    {
        id: 4,
        question: "Miten aloitan pienkeräyksen?",
        answer: (
            <span>
                Pienkeräyksen aloittaminen on yksinkertaista - löydät helpot
                kohta kohdalta -ohjeet{" "}
                <a
                    href="/blog/aloita-oma-pienkerays/"
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
    {
        id: 5,
        question: "Mitä on vastikepohjainen joukkorahoitus?",
        answer: (
            <span>
                Vastikepohjaiset joukkorahoitukset ovat keino kerätä rahoitusta
                yritysten tai yksityishenkilöiden projekteihin tai hankkeisiin.
                Vastikepohjaista joukkorahoitusta voi verrata ennakkomyyntiin;
                myyt lopputuotetta, eli tavaraa, palvelua tai elämystä
                ennakkoon. Lue lisää joukkorahoituksista{" "}
                <a
                    href="/blog/mika-on-joukkorahoitus/"
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
    {
        id: 5,
        question: "Miten rahoitan joukkorahoitusta?",
        answer: (
            <span>
                Voit etsiä sopivaa rahoituskohdetta{" "}
                <a href="/joukkorahoitukset" target="_blank">
                    täältä
                </a>
                . Valitse mieleisesi summa ja maksa kätevästi joko pankki- tai
                luottokortilla Google tai Apple Paylla. Älä unohda kertoa
                rahoituskohteesta myös ystävillesi - jaa keräys omissa
                some-kanavissasi!
            </span>
        ),
    },
    {
        id: 6,
        question: "Miten aloitan joukkorahoituksen?",
        answer: (
            <span>
                Joukkorahoituskampanjan aloittaminen on yksinkertaista - löydät
                helpot kohta kohdalta -ohjeet{" "}
                <a
                    href="/blog/aloita-joukkorahoitus/"
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
    {
        id: 7,
        question: "Maksaako palvelun käyttö?",
        answer: (
            <span>
                Pienkeräyksen tai joukkorahoituksen perustaminen sivulle on
                täysin maksutonta. Veloitamme palvelun käytöstä
                palvelunkäyttömaksun, joka on 6 % kerätystä summasta. Lisäksi
                maksupalveluntarjoaja veloittaa oman kulunsa. Löydät lisätietoja
                hinnoittelusta{" "}
                <a
                    href="/blog/palvelun-hinnoittelu/"
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
    {
        id: 8,
        question: "Mitä jos joukkorahoituskampanjan minimitavoite ei täyty?",
        answer: (
            <span>
                Minimitavoitteen tulee olla aina sellainen, jolla rahoituksen
                hakija pystyy toteuttamaan hankkeen ja toimittamaan luvatut
                vastikkeet rahoittajille. Mikäli minimitavoite ei täyty
                kampanja-ajan puitteissa, kampanja keskeytetään ja rahat
                palautetaan rahoittajille. Emme veloita tässä tilanteessa
                alustamme komissiota, mutta järjestäjä joutuu maksamaan
                maksupalveluntarjoajan veloittaman kulun. Löydät lisätietoja
                hinnoittelustamme{" "}
                <a
                    href="/blog/palvelun-hinnoittelu/"
                    target="_blank"
                >
                    täältä
                </a>
                .
            </span>
        ),
    },
];

export const FrequentlyAsked = memo(() => {
    return (
        <StyledContainer id="questions">
            <StyledMainPageBigText>USEIN KYSYTTYÄ</StyledMainPageBigText>
            <StyledQuestionItem>
                {frequentlyAsked.map((d, index) => (
                    <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                        <QuestionAccordion key={index} data={d} />
                    </Grid>
                ))}
            </StyledQuestionItem>
        </StyledContainer>
    );
})

const StyledQuestionItem = styled(Grid)`
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 1140px;
    grid-area: questions;
`;

const StyledContainer = styled('div')`
    max-width: 1800px;
    padding: 100px 0px 20px 0px;
    justify-content: center;
    margin: auto;
    display: grid;
    grid-template-areas:
        "bigtext"
        "questions";
    @media (max-width: 1000px) {
        padding: 50px 50px 0px 50px;
    }
    @media (max-width: 470px) {
        padding: 50px 50px 0px 50px;
    }
`;
