import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Button, Popover } from "@mui/material";
import colors from "../styles/colors";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
    usePopupState,
    bindTrigger,
    bindPopover,
} from "material-ui-popup-state/hooks";
import ExistingCharityList from "./ExistingCharityList";
import ExistingCrowdfundingsList from "./ExistingCrowdfundingsList";
import { useCampaignStore } from "../hooks/useCampaignStore";

export const User = ({ userEmail }) => {
    const popupState = usePopupState({
        variant: "popover",
        popupId: "user-menu",
    });
    const { fundRequests, crowdfunding } = useCampaignStore();
    const isLoggedIn = !!userEmail;
    const text = (
        <StyledText>{`Olet kirjautunut sisään käyttäjänä ${userEmail}`}</StyledText>
    );
    const loginText = (
        <StyledText>
            Voit kirjautua sisään <StyledLink href="/login">täältä</StyledLink>
        </StyledText>
    );

    const logoutHere = (
        <StyledLogout>
            <StyledLogoutLink href="/logout">Kirjaudu ulos</StyledLogoutLink>
        </StyledLogout>
    );

    const renderCampaigns = () => {
        return (
            <StyledCampaignContainer>
                {fundRequests?.length ? (
                    <StyledCampaignHeader>Pienkeräykset</StyledCampaignHeader>
                ) : null}
                {fundRequests?.length ? <ExistingCharityList /> : null}
                {crowdfunding?.length ? (
                    <StyledCampaignHeader>
                        Joukkorahoitukset
                    </StyledCampaignHeader>
                ) : null}
                {crowdfunding?.length ? <ExistingCrowdfundingsList /> : null}
            </StyledCampaignContainer>
        );
    };

    return (
        <StyledDiv>
            <Button {...bindTrigger(popupState)} aria-label="Login">
                <StyledAvatar {...bindTrigger(popupState)}>
                    {isLoggedIn ? (
                        userEmail.slice(0, 1).toUpperCase()
                    ) : (
                        <LockOpenIcon />
                    )}
                </StyledAvatar>
            </Button>
            <Popover
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                {...bindPopover(popupState)}
            >
                <StyledPopperContainer>
                    {isLoggedIn ? (
                        <>
                            {text}
                            {fundRequests?.length || crowdfunding?.length
                                ? renderCampaigns()
                                : null}
                            {logoutHere}
                        </>
                    ) : (
                        loginText
                    )}
                </StyledPopperContainer>
            </Popover>
        </StyledDiv>
    );
};

const StyledDiv = styled("div")`
    @media (max-width: 600px) {
        display: none;
    }
`;

const StyledAvatar = styled(Avatar)`
    background-color: ${colors.brandSalmon} !important;
`;

const StyledText = styled("p")`
    padding: 0px;
    margin: 0px 5px 0px 5px;
    color: ${colors.brandBlackCurrant};
`;

const StyledLink = styled("a")`
    text-decoration: none;
    flex: 1;
    font-size: 14px;
`;

const StyledLogout = styled("p")`
    padding: 0px;
    margin: 0px 5px 0px 5px;
    color: ${colors.brandBlackCurrant};
    text-align: end;
`;

const StyledLogoutLink = styled("a")`
    text-decoration: none;
    flex: 1;
    font-size: 0.9em;
    font-style: italic;
`;

const StyledPopperContainer = styled("div")`
    padding: 10px;
    background-color: ${colors.brandWhite};
`;

const StyledCampaignContainer = styled("div")`
    padding: 10px;
    max-width: 1000px;
`;

const StyledCampaignHeader = styled("p")`
    font-weight: 500;
`;

const StyledCampaign = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
`;

const StyledName = styled("span")`
    font-size: 14px;
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25%;
`;

const StyledStatus = styled("span")`
    font-size: 14px;
    flex: 1;
    max-width: 25%;
`;

const StyledStripeLink = styled("a")`
    text-decoration: none;
    flex: 1;
    font-size: 14px;
`;
