import firebaseApp from "../config/Fire";
import {
    getFirestore,
    addDoc,
    collection as FScollection,
    query,
    where,
    getDocs,
    getDoc,
    doc,
    orderBy,
    startAfter,
    limit as FSlimit,
    updateDoc,
} from "firebase/firestore/lite";
import axios from "axios";
import { RESERVE_KEY } from "../components/utils";
import { cleanDocument } from "../utils";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

const db = getFirestore(firebaseApp);

export async function getUrlSlug(
    charityId,
    charity_name = "",
    campaignType = "fundRequests",
    start_date
) {
    let candidate = slugify(charity_name, {strict: true}).toLowerCase();
    if (candidate?.length === 20) {
        candidate += "-0"
    }
    if (
        (await checkDocumentExistsWithSlug(
            charityId,
            campaignType,
            candidate
        )) &&
        start_date
    ) {
        candidate = `${start_date}-${candidate}`;
        if (
            await checkDocumentExistsWithSlug(
                charityId,
                campaignType,
                candidate
            )
        ) {
            candidate = `${candidate}-${uuidv4()}`;
        }
    }
    return candidate;
}

export async function checkDocumentExistsWithSlug(
    charityId,
    collectionName,
    slugValue
) {
    const q = query(
        FScollection(db, collectionName),
        where("slugUrlValue", "==", slugValue),
        FSlimit(1)
    );

    const snapShot = await getDocs(q);
    if (snapShot.empty) {
        // allow using value
        return false;
    }
    let isSameDoc = false;
    snapShot.forEach((d) => {
        if (d.id === charityId) {
            isSameDoc = true;
        }
    });
    return !isSameDoc;
}

export async function getDocument(collection, id) {
    if (
        ![
            "charities",
            "crowdfunding",
            "fundRequests",
            "donateDrafts",
            "crowdfundingDrafts",
        ].includes(collection)
    ) {
        return {};
    }
    if (id.length === 20 && !id.includes("-")) {
        const data = await getDoc(doc(db, collection, id));
        return cleanDocument({...data.data(), id: data.id});
    }

    const q = query(
        FScollection(db, collection),
        where("slugUrlValue", "==", id),
        FSlimit(1)
    );
    const snapShot = await getDocs(q);
    if (snapShot.empty) {
        // allow using value
        return {};
    }
    const data = [];
    snapShot.forEach((d) => {
        data.push(cleanDocument({...d.data(), id: d.id}));
    });
    return data?.[0] || {};
}

export async function getFullDocument(collection, id) {
    if (
        ![
            "charities",
            "crowdfunding",
            "fundRequests",
            "donateDrafts",
            "crowdfundingDrafts",
        ].includes(collection)
    ) {
        return [];
    }
    const data = await getDoc(doc(db, collection, id));
    return data.data();
}

export async function getDocuments(
    collectionName,
    category,
    afterValue,
    keyword,
    limit
) {
    /*
        category

        keyword

        default

        if category + keyword, keywords are filtered after the results are fetched
    */
    if (!["crowdfunding", "fundRequests"].includes(collectionName)) {
        return [];
    }
    const docs = [];
    let snapShot;
    if (category.length) {
        if (!Array.isArray(category)) {
            category = [category];
        }
        // filtering
        if (afterValue) {
            // pagination
            const q = query(
                FScollection(db, collectionName),
                where("tags", "array-contains-any", category),
                where("hidden", "==", false),
                orderBy("start_date", "desc"),
                startAfter(afterValue),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        } else {
            const q = query(
                FScollection(db, collectionName),
                where("tags", "array-contains-any", category),
                where("hidden", "==", false),
                orderBy("start_date", "desc"),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        }
    } else if (keyword) {
        if (afterValue) {
            // pagination
            const q = query(
                FScollection(db, collectionName),
                where(
                    "keywords",
                    "array-contains-any",
                    keyword.toLowerCase().split(" ")
                ),
                where("hidden", "==", false),
                orderBy("charity_name"),
                startAfter(afterValue),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        } else {
            const q = query(
                FScollection(db, collectionName),
                where(
                    "keywords",
                    "array-contains-any",
                    keyword.toLowerCase().split(" ")
                ),
                where("hidden", "==", false),
                orderBy("charity_name"),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        }
    } else {
        if (afterValue) {
            // pagination
            const q = query(
                FScollection(db, collectionName),
                where("hidden", "==", false),
                orderBy("start_date", "desc"),
                startAfter(afterValue),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        } else {
            const q = query(
                FScollection(db, collectionName),
                where("hidden", "==", false),
                orderBy("start_date", "desc"),
                FSlimit(limit)
            );
            snapShot = await getDocs(q);
        }
    }
    if (!snapShot) {
        return [];
    }
    snapShot.forEach((i) =>
        docs.push(cleanDocument({ id: i.id, ...{ ...i.data() } }))
    );
    if (category.length && keyword) {
        const keywordArray = keyword.toLowerCase().split(" ");
        return docs.filter((i) =>
            i.keywords.some((word) => keywordArray.includes(word))
        );
    }
    return docs;
}

export const getMyDocuments = async (collectionName, uid) => {
    const docs = [];
    let snapShot;
    if (!["crowdfunding", "fundRequests"].includes(collectionName)) {
        return [];
    }
    const q = query(
        FScollection(db, collectionName),
        where("owners", "array-contains-any", [uid])
    );
    snapShot = await getDocs(q);
    if (!snapShot) {
        return [];
    }
    snapShot.forEach((i) => docs.push({ id: i.id, ...{ ...i.data() } }));
    return docs;
};

export const getUrl = () => {
    if (window?.location?.host === "parempiatekoja.fi") {
        return "https://europe-west1-parempiatekoja-production.cloudfunctions.net";
    }
    // uses staging backend as default
    // return e.g. localhost if using firebase serve --only function
    // return "http://localhost:5000/parempiatekoja-staging-56674/europe-west1";
    return "https://europe-west1-parempiatekoja-staging-56674.cloudfunctions.net";
    /*return window?.location?.origin.includes(":3000") ||
        window?.location?.origin.includes(":5000")
        ? "http://localhost:5000/parempiatekoja-staging-56674/europe-west1"
        : "https://europe-west1-parempiatekoja-staging-56674.cloudfunctions.net";
    */
};

export async function uploadImage(
    file,
    imageWidth = null,
    imageHeight = null,
    withoutEnlargement = false
) {
    const formData = new FormData();
    formData.append("image", file);
    const headers = {
        "Content-Type": file.type,
    };
    const resizeParams = `${imageWidth ? `&width=${imageWidth}` : ""}${
        imageHeight ? `&height=${imageHeight}` : ""
    }${withoutEnlargement ? "withoutEnlargement=true" : ""}`;
    const uri = resizeParams
        ? `${getUrl()}/uploadImage?${resizeParams}`
        : `${getUrl()}/uploadImage`;
    const { data } = await axios.post(uri, formData, {
        headers,
    });
    return data;
    /*const storageRef = storage.ref();
    const charityImagesRef = storageRef.child(`charity-images/${file.name}`);
    const result = await charityImagesRef.put(file)
    console.log(result)
    return result.metadata.fullPath*/
}

export async function createDonationDraft(data, collection = "fundRequests") {
    // add validation
    /*
    SCHEMA
    {
        charity_name:
        content:
        status: "waiting"
        type: "crowdfund" "smallfund"

    }
    */
    const copyOfData = { ...data };
    copyOfData["keywords"] = tokenizeName(data.charity_name);
    copyOfData["created"] = new Date().toISOString();
    const keys = Object.keys(copyOfData);
    keys.forEach((key) => {
        if (copyOfData[key] === null || copyOfData[key] === undefined) {
            delete data[key];
        }
    });
    const route =
        collection === "fundRequests" ? "pienkerays" : "joukkorahoitus";
    const res = await addDoc(FScollection(db, collection), copyOfData);
    const charityId = res.id;
    const returnPath = `${route}/${charityId}/muokkaa#julkaise`;
    const { data: stripeAccountData } = await axios.post(
        `${getUrl()}/createStripeAccount`,
        { returnPath }
    );
    const stripeAccountId = stripeAccountData?.accountId;
    const stripeAccountLink = stripeAccountData?.accountLink;
    await updateDoc(doc(db, collection, charityId), {
        owners: copyOfData.owners,
        stripeAccountId,
        stripeAccountLink,
    });

    return { id: charityId, stripeAccountLink };
}

function tokenizeName(s) {
    const words = s.split(" ");
    let tokenized = new Set();
    words.forEach((word) => {
        if (word.length > 3) {
            for (let x = 3; x <= word.length; x += 1) {
                tokenized.add(word.slice(0, x).toLowerCase());
            }
        }
    });
    return Array.from(tokenized);
}

export async function getBlogs({ pageParam = 1 }) {
    const url = `https://blog.parempiatekoja.fi/ghost/api/v3/content/posts/?key=918271b5381c02ff7e406a982d&limit=20${
        pageParam > 1 ? `&page=${pageParam}` : ""
    }`;
    try {
        const { data = [] } = await axios.get(url).catch((err) => {
            console.error(err);
        });
        return data;
    } catch (err) {
        return { posts: [] };
    }
}

export async function getBlogPost({ queryKey }) {
    const slug = queryKey[1];
    if (!slug) {
        return {};
    }
    const url = `https://blog.parempiatekoja.fi/ghost/api/v3/content/posts/slug/${slug}?key=918271b5381c02ff7e406a982d`;
    try {
        const { data = {} } = await axios.get(url).catch((err) => {
            console.error(err);
        });
        return data;
    } catch (err) {
        return {};
    }
}

export async function acceptCharityApplication(id, charityType) {
    try {
        const response = await axios.put(
            `${getUrl()}/acceptCharityForm`,
            { id, charityType },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (err) {
        console.log(err.response);
        return err.response;
    }
}

export async function rejectCharityApplication(id, charityType) {
    try {
        const response = await axios.put(
            `${getUrl()}/rejectCharityForm`,
            { id, charityType },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (err) {
        console.log(err.response);
        return err.response;
    }
}

export async function hideCharity(id, collection) {
    await updateDoc(doc(db, collection, id), {
        hidden: true,
        status: "hidden",
    });
}

export async function unHideCharity(id, collection) {
    await updateDoc(doc(db, collection, id), {
        hidden: false,
        status: "accepted",
    });
}

export async function isAdminUser() {
    try {
        const { data } = await axios.get(`${getUrl()}/isAdminUser`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return data;
    } catch (err) {
        return false;
    }
}

export async function informAboutSuccessfullFormSubmit(payload) {
    try {
        const { data } = await axios.put(
            `${getUrl()}/informAboutSuccessfullFormSubmit`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return data;
    } catch (err) {
        console.error(err);
    }
}

export async function formMarkedAsReadyEmail(payload) {
    try {
        const { data } = await axios.put(
            `${getUrl()}/formMarkedAsReadyEmail`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return data;
    } catch (err) {
        console.error(err);
    }
}

export async function informAdmin(payload) {
    /*
        payload:
            charityPath: string
            charityName: string
    */
    try {
        const { data } = await axios.put(`${getUrl()}/informAdmin`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return data;
    } catch (err) {
        console.error(err);
    }
}

export async function reservePurchaseItem(
    id,
    action,
    maxItems,
    count = 1,
    reserveKey = null
) {
    const putData = {
        id,
        reserveKey: reserveKey || RESERVE_KEY,
        action,
        maxItems,
        count,
    };
    const { data } = await axios.put(
        `${getUrl()}/setCrowdfundingItemCounts`,
        putData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return data;
}

export async function getPaymentIntent(paymentIntentId) {
    const { data } = await axios.post(`${getUrl()}/getPaymentIntent`, {
        paymentIntentId,
    });
    return data;
}

export async function editDonationDraft(id, data, collection = "fundRequests") {
    const copyOfData = { ...data };
    if (data.charity_name) {
        copyOfData["keywords"] = tokenizeName(data.charity_name);
    }
    copyOfData["edited"] = new Date().toISOString();

    const slugUrlValue = await getUrlSlug(
        id,
        data.charity_name,
        collection,
        data.start_date
    );
    const sortStartValue = `${data.start_date}-${data.charity_name
        .replaceAll(" ", "")
        .toLowerCase()}`;
    const sortEndValue = `${data.due_date}-${data.charity_name
        .replaceAll(" ", "")
        .toLowerCase()}`;

    copyOfData.slugUrlValue = slugUrlValue;
    copyOfData.sortStartValue = sortStartValue;
    copyOfData.sortEndValue = sortEndValue;

    if (!id) {
        console.error("id required");
        return;
    }
    const keys = Object.keys(copyOfData);
    keys.forEach((key) => {
        if (copyOfData[key] === null || copyOfData[key] === undefined) {
            delete copyOfData[key];
        }
    });
    await updateDoc(doc(db, collection, id), copyOfData);
}

export async function stripeAccountStatus(stripeAccountId) {
    const { data } = await axios.put(`${getUrl()}/stripeAccountStatus`, {
        stripeAccountId,
    });
    return data;
}
