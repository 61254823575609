export default {
    // brand colors
    dark: "rgba(62, 79, 88, 1)",
    brandWhite:'#F2EFEB',
    brandSalmon:'#D96666',
    brandGreen:'#73BF9C',
    brandRed:'#F24968',
    brandBlackCurrant: '#0D0126',
    brandGray: '#f8f6f2',
    //main pallette
    teal: "#bee5ee",
    tealLight: "#86bfbf",
    honeySuckle: "#C15B78",
    ivory: "#EBF5F7",
    roseQuarts: "#F6C8CC",
    grayLight: "#C7CDD6",
    grayDark: "#6E7075",
    mainBlue: "#608dfd",
    lightBlue: "#a6eeef",

    mainHeaderGray: "#3E4F58",
    mainGray: "#3E4F58",

    //
    blackRaw: "#000000",
    gray2: "rgb(206, 206, 206)",
    gray3: "#575757",
    grayLightBorder: "#707070",
    grayBackground: "#C7CDD6",
    grayBackgroundTransparent: "rgba(250, 250, 250, 0.9)",
    grayBackgroundButtonColor: "rgba(100, 100, 100, 0.1)",
    whiteBackgroundTransparent: "rgba(255, 255, 255, 0.98)",
    blue: "#70bff7",
    blue2: "#6993ff",
    blueLight: "#f5fbfe",
    blueLight2: "#E5F3FE",
    blueChart: "#70bff7",
    blueTransparent: "rgba(17, 148, 242, 0.15)",
    gray: "#dcdcdc",
    black: "#000",
    black2: "#2a2a2a",
    black2Transparent: "rgba(42, 42, 42, 0.9)",

    /* Magenta palette */
    magenta: "#F13666",
    magentaLight: "#FDE1E8",
    magentaLight2: "rgba(241,51,102,0.1)",

    /* Yellow */
    yellow: "#1097FF",
    yellowLight: "#FFFCD9",
    yellowChart: "#f7c170",
    yellowTransparent: "rgba(255, 230, 0, 0.15)",

    /* Orange palette */
    orange: "#F29711",
    orangeLight: "#FEF0DC",

    /* Green palette */
    green: "#2EC4B6",
    greenLight: "#E0F6F4",

    /* Violet */
    violet: "#750F75",
    violetLight: "#E3C8E3",

    /* White */
    white: "#ffffff",

    /* GrayBlue */
    backgroundGrayBlue: "#f6f5fa",
};
