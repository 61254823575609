import { create } from 'zustand'

export const useUserStore = create((set) => ({
    user: {},
    loggedIn: false,
    signInEmailSent: false,
    isAdmin: false,
    logout: () => set(({ user: {}, loggedIn: false })),
    setIsAdmin: (isAdmin) => set(({ isAdmin })),
    setUser: (user) => set(({ user, loggedIn: !!user?.uid })),
    setSignInEmailSent: (signInEmailSent) => set({ signInEmailSent  }),
}))
