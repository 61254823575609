import { useQuery } from "@tanstack/react-query";
import { stripeAccountStatus } from "../api";
import { CircularProgress } from "@mui/material";


export const CampaignBankAccountState = (campaign) => {
    const { data: stripeAccountStatusValue, isLoading } = useQuery({
        queryKey: [`stripeAccountStatus-${campaign?.stripeAccountId}`],
        queryFn: () => stripeAccountStatus(campaign?.stripeAccountId),

        enabled: !!campaign?.stripeAccountId,
        retry: 0,
    });
    const stripeAccountIsValidated = !!stripeAccountStatusValue?.status;

    let campaignAccountState;
    if (isLoading) {
        return <CircularProgress size={20} />;
    }
    if (stripeAccountIsValidated) {
        campaignAccountState = "Kyllä";
    } else if (campaign.stripeAccountLink) {
        campaignAccountState = "Keskeneräinen";
    } else {
        campaignAccountState = "Ei";
    }
    return <div>{campaignAccountState}</div>;
};