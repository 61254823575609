import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import {
    initializeAuth,
    browserLocalPersistence,
    indexedDBLocalPersistence,
    browserPopupRedirectResolver,
    inMemoryPersistence,
    getAuth,
} from "firebase/auth";

const isProduction = () => {
    if (typeof global?.app !== "undefined") {
        if(global?.app?.isProduction) {
            return true
        }
    }
    if (typeof window !== "undefined") {
        if (window?.location?.host === "parempiatekoja.fi") {
            return true;
        }
    }
    return false;
};

const productionConfig = {
    apiKey: "AIzaSyCNpFRh8TW66hsfhRMXznbVhQYsGISST_8",
    authDomain: "parempiatekoja.fi",
    databaseURL:
        "https://parempiatekoja-production-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "parempiatekoja-production",
    storageBucket: "parempiatekoja-production.appspot.com",
    messagingSenderId: "539513788647",
    appId: "1:539513788647:web:e2a2d6f268a8b053a5aa42",
    measurementId: "G-H0FRMHVBEH",
    automaticDataCollectionEnabled: false,
};

const devAndStatingConfig = {
    apiKey: "AIzaSyC-2ZPerfVxOGlcrEINZ_fXQJENDOApXl4",
    authDomain: "parempiatekoja-staging-56674.firebaseapp.com",
    databaseURL:
        "https://parempiatekoja-staging-56674-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "parempiatekoja-staging-56674",
    storageBucket: "parempiatekoja-staging-56674.appspot.com",
    messagingSenderId: "452097394749",
    appId: "1:452097394749:web:df12157d24ca7009024254",
    measurementId: "G-C31ZQ43EW4",
};

export const firebaseApp = initializeApp(
    isProduction() ? productionConfig : devAndStatingConfig
);

const SSR = typeof window === "undefined"

export const authInstance = SSR ? getAuth(firebaseApp) : initializeAuth(firebaseApp, {
    persistence: SSR
        ? inMemoryPersistence
        : [indexedDBLocalPersistence, browserLocalPersistence],
    ...(!SSR && { popupRedirectResolver: browserPopupRedirectResolver }),
});


export const enableAnalytics = () => {
    if (SSR) {
        return;
    }
    window.analytics = { automaticDataCollectionEnabled: false };
    if (isSupported()) {
        window.analytics = getAnalytics(firebaseApp);
        window.analytics.automaticDataCollectionEnabled = true;
    }
};

export const doNotTrack = () => {
    if (SSR) {
        return;
    }
    try {
        window[`ga-disable-${window?.analytics.app._options.measurementId}`] = true;
    } catch (err) {
        
    }
};

export default firebaseApp;
